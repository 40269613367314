
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { getSession } from "../store/actions/authActions";
import Modal from "react-bootstrap/Modal";
const SiteFooterTop = () => {
    let { Authorization } = getSession();

    //   modal for view demo website links
    const [showDemoWebsite, setShowDemoWebsite] = useState(false);
  
    const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
    const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
    //   modal for view demo website links
  return (
    <section className="cta">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="cta_inner">
            <div className="cta_thought vertical_center">
              {/* टेक्नोलॉजी पर हम फोकस करेंगे, आप बस अपने बिज़नेस पर ध्यान दो ! */}
              We will focus on the technology, you just focus on your business!
            </div>
            <div className="cta_btn">
              {/* आज ही अपना बिज़नेस ऑनलाइन करो */}
              Get your business online today.
              <div className="theme_btn_site">
                {Authorization ? (
                  <Link to="/account"> My Account</Link>
                ) : (
                  <Link to="/login">Create Website @ just ₹150/m</Link>
                )}
              </div>
              {/* {!Authorization && (
              <>
                <div className="vg12"></div>
                <Link to="/login" className="animated_text white">
                  Free Trial Available for 7 Days
                </Link>
              </>)} */}
              {/* <div className="vg12"></div>
              <div className="d-flex align-items-center justify-content-center" style={{
                gap: "5px",
                color: "white",
                fontSize: "14px"
              }}
                onClick={handleShowDemoWebsiteModal}>
                <span class="material-symbols-outlined" style={{
                  fontSize: "20px"
                }}>
                  visibility
                </span>
                <span className="">View Demo Website</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* modal for show demo website links  */}
      <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                  Shree Sanwariya Decoration
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
  </section>
  )
}

export default SiteFooterTop
