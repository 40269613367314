import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSession } from "../store/actions/authActions";
import Modal from "react-bootstrap/Modal";

// import css
import "./bbwebsitecss.css";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import LiveChatScript from "./LiveChatScript";
import SiteFooterTop from "./SiteFooterTop";

import useRefferalCode from "../common/hooks/useRefferalCode";
import GoogleMetaTag from "../component/GoogleMetaTag";
import useScrollToTop from "../common/hooks/useScrollToTop";

const WebsiteHome = () => {
  useRefferalCode();
  useScrollToTop();
  let { Authorization } = getSession();

  //   modal for view demo website links
  const [showDemoWebsite, setShowDemoWebsite] = useState(false);

  const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
  const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
  //   modal for view demo website links


  return (
    <div className="website_pages">
      <GoogleMetaTag title={"Create own website in just ₹150/m | Top Website builder"} content={"At BusinessBaab you can create own professional website in just 15 minutes with no code website. That too "} />
      <SiteHeader />
      <section className="hero vertical_center eng_content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero_content">
                {/* <h1>अब इंडिया का हर बिज़नेस होगा ऑनलाइन</h1> */}
                <h1>Top Website Builder</h1>
                {/* <h2>
                  सिर्फ ₹150 माह में अपने बिज़नेस की वेबसाइट बनाएँ, वो भी 10 मिनट
                  में
                </h2> */}
                <h2>Just ₹150/month - No Hidden Cost</h2>
                {/* <h2 className="mb-0">
                  और हाँ, डोमेन और होस्टिंग का भी झंझट नहीं!
                </h2> */}
                <div className="line"></div>
                <h3 className="animated_text">
                  Create your business website/Store in just 15 minutes <br /> And yes, no hassle of domain and hosting!
                </h3>
                <div className="hero_btn">
                  <div className="theme_btn_site">
                    {Authorization ? (
                      <Link to="/account"> My Account</Link>
                    ) : (
                      <Link to="/login">Create Website @ just ₹150/m</Link>
                      // <Link to="/login">Free Trial for 7 Days</Link>
                    )}

                    {/* <img
                      src="assets/bbsite_images/btn_curve_arrow.svg"
                      alt="Arrow"
                    /> */}
                    <div className="vg12"></div>
                    <div className="grad_text d-flex align-items-center justify-content-center" style={{
                      gap: "5px"
                    }}
                      onClick={handleShowDemoWebsiteModal}>
                      <span class="material-symbols-outlined" style={{
                        fontSize: "20px"
                      }}>
                        visibility
                      </span>
                      <span className="gt_border grad_text">View Demo Website</span>
                    </div>
                    <div className="vg12"></div>
                    {/* <h3>
                      Create your own website @ just ₹<span>83</span>/m
                    </h3> */}
                    <h5>No Hidden Cost</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about vertical_center"
        style={{
          backgroundImage: "url('./assets/bbsite_images/map_india.svg')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about_content">
                <div className="g_logos">
                  <img
                    src="assets/bbsite_images/logo_make_in_india.svg"
                    alt="Logo"
                  />
                  <img
                    src="assets/bbsite_images/logo_startupindia.svg"
                    alt="Logo"
                  />
                </div>
                <div className="theme_title border_title relative">
                  <h2>
                    BusinessBaab:
                    <br />
                    <span>Your Gateway to Online Success</span>
                  </h2>
                </div>
                <p>
                  Explore a streamlined platform designed to present your
                  business effectively on the web. Whether you're an e-commerce
                  store, service specialist, dining establishment, lodging
                  provider, healthcare practitioner, retail outlet, educational
                  establishment, travel organizer, agricultural producer,
                  educator, or public figure, BusinessBaab presents the chance
                  to craft an impressive website effortlessly. Showcase your
                  products, services, or expertise, engage with your target
                  audience, and broaden your online presence—all at a
                  pocket-friendly rate. With intuitive tools and unwavering
                  assistance, propel your business to unprecedented success in
                  the online sphere.
                </p>
                <div className="vg22"></div>
                <div className="theme_btn_site">
                  {Authorization ? (
                    <Link to="/account"> My Account</Link>
                  ) : (
                    <Link to="/login">Create Website @ just ₹150/m</Link>
                  )}
                </div>

                {/* {!Authorization && (
                  <>
                    <div className="vg12"></div>
                    <Link to="/login" className="animated_text">
                      Free Trial Available for 7 Days
                    </Link>
                  </>)} */}
                <div className="vg12"></div>
                <div className="grad_text d-flex align-items-center " style={{
                  gap: "5px"
                }}
                  onClick={handleShowDemoWebsiteModal}>
                  <span class="material-symbols-outlined" style={{
                    fontSize: "20px"
                  }}>
                    visibility
                  </span>
                  <span className="gt_border grad_text">View Demo Website</span>
                </div>
                <div className="vg22"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="showcase_mobile relative">
                <div className="showcase relative">
                  <img
                    src="assets/bbsite_images/blank_screen_top.png"
                    alt="Showcase"
                    className="screen_top"
                  />
                  <img
                    src="assets/bbsite_images/blank_screen.svg"
                    alt="Showcase"
                  />
                </div>
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide showcase_slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleSlidesOnly"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleSlidesOnly"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleSlidesOnly"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    ></button>
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/bbsite_images/screen_inner4.jpg"
                        className="d-block w-100"
                        alt="Showcase Slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/bbsite_images/screen_inner5.jpg"
                        className="d-block w-100"
                        alt="Showcase Slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/bbsite_images/screen_inner6.jpg"
                        className="d-block w-100"
                        alt="Showcase Slide"
                      />
                    </div>

                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="vg12"></div>
                <div className="grad_text d-flex align-items-center justify-content-center mbv" style={{
                  gap: "5px",
                  width: "205px"
                }}
                  onClick={handleShowDemoWebsiteModal}>
                  <span class="material-symbols-outlined" style={{
                    fontSize: "20px"
                  }}>
                    visibility
                  </span>
                  <span className="gt_border grad_text">View Demo Website</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="pricing_plan">
        <div className="container">
          <div className="pricing_plan_inner">
            <div className="ppi_sect1 ppi_sect">
              <h2 className="text-center light title">
                Pricing Plan
              </h2>
              <h3 className="subtitle">
                Explore our affordable pricing options for both monthly and yearly plans
              </h3>
              <div className="plans">
                <div className="plans_single monthly">
                  <div className="ps_inner">
                    <h4>
                      ₹249
                    </h4>
                    <h5>
                      Monthly
                    </h5>
                  </div>
                </div>
                <div className="plans_single yearly">
                  <div className="tag">
                    Popular
                  </div>
                  <div className="ps_inner">
                    <h4>
                      ₹1799
                    </h4>
                    <h5>
                      Yearly
                    </h5>
                  </div>
                  <div className="per_month">
                    ₹150/m
                  </div>
                </div>
              </div>

              {Authorization ? (
                <Link to="/account" className="theme_btn short_btn" style={{
                  margin: "0px auto"
                }}> My Account</Link>
              ) : (
                <Link to="/login" className="theme_btn short_btn" style={{
                  margin: "0px auto"
                }}>Create Website @ just ₹150/m</Link>
              )}
              {/* Start with Free Trial for 7 Days */}

              <div className="vg12"></div>
              <div className="grad_text d-flex align-items-center justify-content-center" style={{
                gap: "5px"
              }}
                onClick={handleShowDemoWebsiteModal}>
                <span class="material-symbols-outlined" style={{
                  fontSize: "20px"
                }}>
                  visibility
                </span>
                <span className="gt_border grad_text">View Demo Website</span>
              </div>

            </div>
            <div className="ppi_sect2 ppi_sect">
              <h2 className="text-center light title">
                Features
              </h2>
              <h3 className="subtitle">
                Unlock a world of features to customize your website and drive business growth
              </h3>
              <div className="feature_points">
                <div className="row">
                  <div className="col-md-6">
                    <div className="fp_group">

                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Superfast Website Speed
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Fully Editable
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Full Business Info
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Listing Products / Services
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        User's Order Management
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Leads Order / Done Order History
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Payment integration (UPI)
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Buyer History
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Inventory Management
                      </div>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="fp_group">

                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Up to 50 Products / Services
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Generate Invoice for Customers
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Multiple Theme Layouts
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Multiple Theme Colors
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Dashboard
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Analytics
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Unique QR Code
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Lead Generation
                      </div>
                      <div className="fp_single">
                        <span class="material-symbols-outlined">
                          done
                        </span>
                        Account Management
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hiw">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="theme_title color_title">
                <h2>How it works?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {/* <div className="hiw_slider">
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="3"
                      aria-label="Slide 4"
                    ></button>
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img
                        src="assets/bbsite_images/slider_img.jpg"
                        className="d-block w-100"
                        alt="Slider Image"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/bbsite_images/slider_img2.jpg"
                        className="d-block w-100"
                        alt="Slider Image"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/bbsite_images/slider_img3.jpg"
                        className="d-block w-100"
                        alt="Slider Image"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src="assets/bbsite_images/slider_img4.jpg"
                        className="d-block w-100"
                        alt="Slider Image"
                      />
                    </div>                  
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div> */}
              <div className="home_video">
                <video src="/assets/videos/home_video3.mp4" className="w-100" muted autoPlay loop></video>
              </div>
            </div>
            <div className="col-lg-6 vertical_center">
              <div className="hiw_content vertica">
                <h3 className="size_20 medium">
                  Create your website with 3 Easy Steps:
                </h3>
                <div className="steps">
                  <div className="step_single relative mb-4">
                    <span>1</span>
                    <h3>Create account using mobile number</h3>
                    <Link to="/">See Documents</Link>
                  </div>
                  <div className="step_single relative mb-4">
                    <span>2</span>
                    <h3>Buy a plan for Monthly or Yearly</h3>
                    <Link to="/">See Documents</Link>
                  </div>
                  <div className="step_single relative">
                    <span>3</span>
                    <h3>Choose category and fill your business info</h3>
                    <Link to="/">See Documents</Link>
                  </div>
                </div>
                <div className="theme_btn_site">
                  {Authorization ? (
                    <Link to="/account"> My Account</Link>
                  ) : (
                    <Link to="/login">Create Website @ just ₹150/m</Link>
                  )}
                </div>
                {/* {!Authorization && (
                  <>
                    <div className="vg12"></div>
                    <Link to="/login" className="animated_text">
                      Free Trial Available for 7 Days
                    </Link>
                  </>)} */}
                <div className="vg12"></div>
                <div className="grad_text d-flex align-items-center " style={{
                  gap: "5px"
                }}
                  onClick={handleShowDemoWebsiteModal}>
                  <span class="material-symbols-outlined" style={{
                    fontSize: "20px"
                  }}>
                    visibility
                  </span>
                  <span className="gt_border grad_text">View Demo Website</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="category">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 vertical_center">
              <div className="cat_content">
                {/* <h2>आपका व्यवसाय जो भी हो, यह सभी के लिए है</h2> */}
                <h2>Whatever your business, it's for everyone</h2>
                {/* <p className="size_16">
                  BusinessBaab अपने व्यवसाय को ऑनलाइन लाने के लिए तैयार एक सहज
                  प्लेटफार्म है। आप जो भी करते हों - <b>1.</b> घर से ही कुछ
                  बेचना चाहते हों जैसे कपड़े, शूज़, ज्वैलरी आदि, <b>2.</b> आपका
                  अपना रेस्टोरेंट हो या घर से खाना बना कर ऑनलाइन सेल करना चाहते
                  हों, <b>3.</b> आप किसी भी फील्ड में सर्विस देते हों या कमीशन
                  एजेंट हों, <b>4.</b> आप डॉक्टर हो किसी हॉस्पिटल में या आपका
                  क्लिनिक हो, <b>5.</b> आपका स्कूल/कॉलेज हो या आप एक कोचिंग चलते
                  हों, <b>6.</b> आपकी ट्रेवल एजेंसी हो या आप अपनी पर्सनल गाड़ी
                  चलाते हों (कार टैक्सी या ऑटो), <b>7.</b> आप एक राजनीतिक नेता
                  हों, <b>8.</b> आपकी होटल या होमस्टे हो, या <b>9.</b> आप एक
                  किसान हों.....{" "}
                  <b>
                    BusinessBaab आपको आसानी से एक शानदार वेबसाइट बनाने का अवसर
                    प्रदान करता है, वो भी आपके बजट में।
                  </b>
                </p> */}
                <p className="size_16">
                  BusinessBaab is a simple platform to bring your business online. Whatever you do - <b>1.</b> You want to sell something from home like clothes, shoes, jewellery etc,{" "}
                   {/* <b>2.</b> You have your own restaurant or want to cook food from home and sell it online, */}
                    <b>2.</b> You provide service in any field or are a commission agent {" "}
                    
                    {/* <b>4.</b> You are a doctor in a hospital or you have a clinic, */}
                     {/* <b>5.</b> You have a school/college or you run a coaching institute, */}
                     
                      <b>3.</b> You have a travel agency or you drive your personal vehicle (car taxi or auto),
                      {/* <b>7.</b> You are a political leader, <b>8.</b>  You have a hotel or homestay, or <b>9.</b> You are a farmer..... {" "} */}
                  <b>
                  {" "}BusinessBaab gives you the opportunity to easily create a great website, that too within your budget.
                  </b>
                </p>
                <div className="vg22"></div>
                <div className="theme_btn_site">
                  {Authorization ? (
                    <Link to="/account"> My Account</Link>
                  ) : (
                    <Link to="/login">Create Website @ just ₹150/m</Link>
                  )}

                </div>
                {/* {!Authorization && (
                  <>
                    <div className="vg12"></div>
                    <Link to="/login" className="animated_text">
                      Free Trial Available for 7 Days
                    </Link>
                  </>)} */}
                <div className="vg12"></div>
                <div className="grad_text d-flex align-items-center " style={{
                  gap: "5px"
                }}
                  onClick={handleShowDemoWebsiteModal}>
                  <span class="material-symbols-outlined" style={{
                    fontSize: "20px"
                  }}>
                    visibility
                  </span>
                  <span className="gt_border grad_text">View Demo Website</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="cat_img">
                <img src="assets/bbsite_images/cat_img2.svg" alt="Image" />
              </div>
            </div>
          </div>
          <div className="row categories">
            <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_1.svg" alt="Icon" />
                <h4>Online Store</h4>
                <p>
                  Sell your products online effortlessly. Showcase your products
                  with stunning visuals and reach a wider audience.
                </p>
              </div>
            </div>
            <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_2.svg" alt="Icon" />
                <h4>Services</h4>
                <p>
                  Let customers find and connect with your services. Highlight
                  your expertise and skills to attract potential clients.
                </p>
              </div>
            </div>
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_3.svg" alt="Icon" />
                <h4>Restaurant</h4>
                <p>
                  Mouth-watering Menus and Inviting Ambiance. Attract diners and
                  travelers effortlessly with your restaurant or hotel's unique
                  offerings.
                </p>
              </div>
            </div> */}
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_4.svg" alt="Icon" />
                <h4>Doctor</h4>
                <p>
                  Build an online presence for healthcare providers. Connect
                  with patients and provide essential information about your
                  medical services.
                </p>
              </div>
            </div> */}
            <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_5.svg" alt="Icon" />
                <h4>Retail Shops</h4>
                <p>
                  Expand your local shop to the online market. Attract customers
                  with special offers, promotions, and a seamless shopping
                  experience.
                </p>
              </div>
            </div>
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_6.svg" alt="Icon" />
                <h4>School/College</h4>
                <p>
                  Showcase your institution’s facilities and programs. Engage
                  with students and parents by providing valuable information
                  about your educational services.
                </p>
              </div>
            </div> */}
            <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_7.svg" alt="Icon" />
                <h4>Travels</h4>
                <p>
                  Promote your travel packages and destinations. Make booking
                  easy and provide travelers with essential travel information
                  to plan their next adventure.
                </p>
              </div>
            </div>
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_8.svg" alt="Icon" />
                <h4>Farmer's</h4>
                <p>
                  Directly reach customers with your farm produce. Highlight
                  your sustainable farming practices and connect with consumers
                  who value locally-sourced products.
                </p>
              </div>
            </div> */}
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_9.svg" alt="Icon" />
                <h4>Teachers</h4>
                <p>
                  Offer online classNamees and tutorials at your fingertips.
                  Share your teaching philosophy and expertise to attract
                  students seeking quality educational content.
                </p>
              </div>
            </div> */}
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_10.svg" alt="Icon" />
                <h4>Politicians</h4>
                <p>
                  Connect with your constituents. Share your vision,
                  accomplishments, and political platform to engage with voters
                  and gain their support.
                </p>
              </div>
            </div> */}
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_13.svg" alt="Icon" />
                <h4>Hotel / Home Stay</h4>
                <p>
                  Showcase your property to travelers worldwide. Create a
                  captivating website and grow your business with BusinessBaab.
                </p>
              </div>
            </div> */}
            {/* <div className="cs col-lg-3 col-md-4 col-6">
              <div className="cat_single">
                <img src="assets/bbsite_images/cat_img_12.svg" alt="Icon" />
                <h4>Personal</h4>
                <p>
                  Showcase your talents, share your interests, and connect with
                  others—all in one place. With BusinessBaab, building your
                  personal brand has never been easier.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="why_businessbaab"
        style={{
          background: "#D6EFFF",
        }}
      >
        <div class="text-center">
          <h1 class="pgtitle">Why BusinessBaab</h1>
        </div>
        <div className="vg30"></div>
        <div className="container">
          <div className="row vertical_center">
            <div className="col-lg-4 left">
              <h5>
                {/* BusinessBaab में, हम आज की ऑनलाइन दुनिया में छोटे व्यवसायों के
                सामने आने वाली चुनौतियों को समझते हैं। इसीलिए हम लाए हैं एक ऐसा
                प्लेटफ़ॉर्म, जो वेबसाइट बनाने की प्रक्रिया को सरल बनाता है। आप
                बस अपने बिज़नेस पर ध्यान दो, टेक्नोलॉजी की सारी प्रोब्लेम्स हम पर
                छोड़ दो सिर्फ ₹150 माह में। */}
                At BusinessBaab, we understand the challenges small businesses face in today's online world. That's why we have come up with a platform that simplifies the process of building a website. You just focus on your business, leave all the technology problems to us for just ₹ 150 per month.
              </h5>
            </div>
            <div className="col-lg-4">
              <div className="about_tech_img">
                <img src="./assets/bbsite_images/about_tech_img.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-4 right">
              <h5>
                {/* आज ही BusinessBaab से जुड़ें और अपने बिज़नेस को ऑनलाइन ले जाने की
                दिशा में पहला कदम बढ़ाएं। आइए हम आपकी ऑनलाइन उपस्थिति बनाने और
                सफलता की नई ऊंचाइयों तक पहुंचने में आपकी सहायता करें। */}
                Join BusinessBaab today and take the first step towards taking your business online. Let us help you build your online presence and reach new heights of success.
              </h5>
              <div className="vg22"></div>
              <div className="theme_btn_site">
                {Authorization ? (
                  <Link to="/account"> My Account</Link>
                ) : (
                  <Link to="/login">Create Website @ just ₹150/m</Link>
                )}

              </div>
              {/* {!Authorization && (
                <>
                  <div className="vg12"></div>
                  <Link to="/login" className="animated_text">
                    Free Trial Available for 7 Days
                  </Link>
                </>)} */}
              <div className="vg12"></div>
              <div className="grad_text d-flex align-items-center justify-content-sm-center justify-content-md-start justify-content-lg-start
              justify-content-center" style={{
                  gap: "5px"
                }}
                onClick={handleShowDemoWebsiteModal}>
                <span class="material-symbols-outlined" style={{
                  fontSize: "20px"
                }}>
                  visibility
                </span>
                <span className="gt_border grad_text">View Demo Website</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooterTop />
      <SiteFooter />
      {/* <LiveChatScript/> */}
      {/* modal for show demo website links  */}
      <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                  Shree Sanwariya Decoration
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
    </div>
  );
};

export default WebsiteHome;
