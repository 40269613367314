import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import BannerSlider from "./BannerSlider";
import SocialLinks from "./SocialLinks";
import PaymentLinks from "./PaymentLinks";
import LimitedProducts from "../Products/LimitedProducts";
import ContactInfo from "./ContactInfo";
import ProfileInActive from "./ProfileInActive";
import { downloadVCard } from "../../../store/actions/publicActions";
import GoogleMetaTag from "../../../component/GoogleMetaTag";
import { getSession } from "../../../store/actions/authActions";
import { CATEGORY_LISTS } from "../../../common/constants";
import LimitedVehicles from "../Travel/LimitedVehicles";
import LimitedServices from "../Travel/LimitedServices";
const HomeThree = ({ profile, limitedService, categoryName }) => {
  // add class before min width 992  start
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // add class before min width 992  end
  const [saving, setSaving] = useState(false);
  let { Authorization, user_id } = getSession();

  const onSavingHandler = () => {
    setSaving(true);
    if (profile?._id)
      downloadVCard(profile?._id, setSaving, profile?.personalInfo?.name);
  };

  if (!profile.isProfileActive) {
    return (
      <ProfileInActive
        profile={profile}
        Authorization={Authorization}
        user_id={user_id}
      />
    );
  }
  return (
    <div className="portal_page_spacing template_2 template_3 home_three relative">
      <GoogleMetaTag
        title={profile?.businessInfo?.title || ""}
        content={profile?.businessInfo?.bio || ""}
      />

      <div className="portal_New from_top home_two ">
        <div className={isMobile ? "" : "container"}>
          <div className="ht_header">
            <section className="portal_logo desktop_hide">
              {profile?.businessInfo?.logo ? (
                <img
                  src={profile?.businessInfo?.logo}
                  alt={profile?.businessInfo?.title}
                />
              ) : (
                <>
                  <div className="vg12"></div>
                  <h2 className="make_logo grad_text">
                    {profile?.businessInfo?.title}
                  </h2>
                  <div className="vg12"></div>
                </>
              )}
            </section>
            <PortalNavbar
              title="Analytics"
              backgroundColor="transparent"
              backArrow="arrow_back"
              isHide={true}
            />
          </div>
        </div>
        <section className="about_portal ht_banner">
          <div className={`head head_top_bg text-center ${isMobile ? "" : "sect_bg"}`}>
            <div className={isMobile ? "" : "container"}>
              <div className="conbg">
                {profile?.businessInfo?.images && profile?.businessInfo?.images.length > 0 ? (
                  <BannerSlider images={profile?.businessInfo?.images} />
                ) : (
                  <section className="banner from_left_right relative">
                    <img src="/assets/img/slider_image_bydefault.jpg" alt="" />
                  </section>
                )}
                <div className="vg12"></div>
                <h5 className="m22 white">{profile?.businessInfo?.title || ""}</h5>
                <div className="vg8"></div>
                <h6 className="r16 lh22 textarea_text white regular" style={{
                  lineHeight: "25px",
                  opacity: 0.75,
                }}>{profile?.businessInfo?.bio || ""}</h6>
                <div className="vg22"></div>
                <Link
                  to={`/${profile?.publicIdentifier}/products`}
                  className="grad_text gt_border grad_text_white white_border"
                >
                  View more
                </Link>
              </div>
            </div>
          </div>
        </section>

        {profile?.categoryName === CATEGORY_LISTS.TRAVEL ? (
            <>
              {" "}
              {limitedService?.vehicles?.length > 0 && (
                <LimitedVehicles
                  limitedService={limitedService}
                  services={limitedService?.vehicles}
                  categoryName={categoryName}
                  heading={"Vehicles"}
                  type={"vehicleInfo"}
                />
              )}{" "}
              {limitedService?.services?.length > 0 && (
                <LimitedServices
                  limitedService={limitedService}
                  services={limitedService?.services}
                  categoryName={categoryName}
                  heading={"Services"}
                  type={"serviceInfo"}
                />
              )}{" "}
              {limitedService?.packages?.length > 0 && (
                <LimitedVehicles
                  limitedService={limitedService}
                  services={limitedService?.packages}
                  categoryName={categoryName}
                  heading={"Packages"}
                  type={"packageInfo"}
                />
              )}
            </>
          ) : (
            <>
              {" "}
              {limitedService?.services?.length > 0 && (
                <LimitedProducts
                  limitedService={limitedService}
                  categoryName={categoryName}
                />
              )}
            </>
          )}
        <div className={isMobile ? "" : "sect_bg"}>
          <div className={isMobile ? "" : "container"}>
            <section className="contact_now sect_padding from_left_right">
            <div className="contact_now_inner desktop_sect_width">
              <ContactInfo profile={profile} />

              {profile?.paymentInfo && profile?.paymentInfo.length > 0 && (
                <PaymentLinks paymentLink={profile?.paymentInfo} />
              )}

              {profile?.social && profile?.social.length > 0 && (
                <SocialLinks socialLinks={profile?.social} />
              )}

              <div className="vg22"></div>
              {/* <button
              className="theme_btn btn_border"
              disabled={saving}
              onClick={onSavingHandler}
            >
              {saving ? "Saving..." : " Save in Contact Number"}
            </button> */}
            </div>
            </section>
            <div className="footer text-center">
              <div className="vg12"></div>
              {/* <h6 className="grad_text gt_border">Feedback</h6> */}

              {/* {!profile?._id && (
              <>   <div className="vg8"></div>
                <Link className="theme_btn short_btn margin_auto">
  
                  1 Day Free - Try Now
                </Link>
              </>
            )} */}
              {!Authorization && (
                <Link className="grad_text gt_border" to="/intro">
                  Create Website @ just ₹150/m
                </Link>
              )}


              {/* {!Authorization && (
              <h6 className="m16">
                Create your own portal @ just ₹{" "}
                <span className="sb22"> 249/m</span>
              </h6>
            )} */}
              {/* {!Authorization && (
              <>
                <div className="vg12"></div>
                <Link to="/login" className="animated_text">
                  Free Trial Available for 7 Days
                </Link>
              </>)} */}

              <div className="vg8"></div>
              <h6 className="r12">
                <span
                  style={{
                    opacity: "0.8",
                  }}
                >
                  Powered by
                </span>{" "}
                <Link
                  to="https://absne.in/"
                  target="_blank"
                  className="black medium"
                >
                  Absne Private Limited
                </Link>
              </h6>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
};

export default HomeThree;
